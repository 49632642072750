import {
  SET_LOGIN_AUTH,
  SET_LOGOUT_AUTH,
  INITIALIZE_APP,
  SET_LOGIN_ADMIN_AUTH,
  SET_LOGOUT_ADMIN_AUTH,
  SET_LOGOUT_ADVISOR_AUTH,
  SET_LOGIN_ADVISOR_AUTH
} from './Index';

// For logging in the user
export const _login = (
  authToken: string,
  initialRoute?: string,
  oauth?: string
): any => async (dispatch: any) => {
  dispatch({type: SET_LOGIN_AUTH, payload: {authToken, oauth, initialRoute}});
};

export const _adminLogin = (
  authToken: string,
  initialRoute?: string,
  oauth?: string
): any => async (dispatch: any) => {
  dispatch({
    type: SET_LOGIN_ADMIN_AUTH,
    payload: {authToken, oauth, initialRoute}
  });
};

export const _advisorLogin = (
  authToken: string,
  initialRoute?: string,
  oauth?: string
): any => async (dispatch: any) => {
  dispatch({
    type: SET_LOGIN_ADVISOR_AUTH,
    payload: {authToken, oauth, initialRoute}
  });
};

export const _logout = (): any => async (dispatch: any) => {
  dispatch({type: SET_LOGOUT_AUTH, payload: {}});
};

export const _adminLogout = (): any => async (dispatch: any) => {
  dispatch({type: SET_LOGOUT_ADMIN_AUTH, payload: {}});
};

export const _advisorLogout = (): any => async (dispatch: any) => {
  dispatch({type: SET_LOGOUT_ADVISOR_AUTH, payload: {}});
};

export const _initApp = (config: any, data: any): any => async (
  dispatch: any
) => {
  dispatch({type: INITIALIZE_APP, payload: {config, data}});
};
