import {baseEnvironment} from './environment.base';
export const environment = {
  ...baseEnvironment,
  API_URL: '/prodEnvUrl',
  dev: false,
  production: true,
  baseUrl: 'https://core.healpha.com/api/v1',
  phpUrl: 'https://core.healpha.com/api',
  baseHost: 'healpha.com',
  encryptLocalStorage: true,
  encryptLocalStorageKey: 'f74808fb03f94ef8f64350a37918c1b0518c',
  azureClientId: '7f26bbad-0d50-4cdc-af9c-f33e42bb43d7',
  azureTenantId: '7cd4c483-9d86-49b2-ab98-97b0446f78af',
  portalUrl: 'https://core.healpha.com',
  paymentProvider: 'razorpay',
  paymentProviderKeyId: 'rzp_live_GRPcZ7gUHTnvXm',
  paymentCurrency: 'INR',
  currencyValue: 'Rs',
  countryCode: 'in',
  dialCode: '+91',
  mobileNumberLength: 10,
  pinCodeNumberLength: 6,
  instanceType: 'core',
  languages: [
    {
      key: 'en',
      full_key: 'english',
      label: 'English'
    }
  ],
  country: {
    id: 101,
    sortname: 'IN',
    name: 'India',
    phonecode: 91,
    folderName: 'IND'
  },
  firebase: {
    apiKey: 'AIzaSyC4D6Ei2rol13a7RKF-TvpR4xSI3zO1xxo',
    authDomain: 'healpha-person.firebaseapp.com',
    databaseURL: 'https://healpha-person.firebaseio.com',
    projectId: 'healpha-person',
    storageBucket: 'healpha-person.appspot.com',
    messagingSenderId: '446026613801',
    appId: '1:446026613801:web:fb633ff11998cfd70c8838',
    measurementId: 'G-FN4FF0ZW66'
  },
  websiteNavigationUrl: 'https://healpha.com'
};
