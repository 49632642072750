export const APP_PRIMARY_FONT_REGULAR = 'Nunito Sans';
export const APP_SECONDARY_FONT_REGULAR = 'PT Serif';

export const BACKGROUND_PRIMARY_COLOR = '#F5F4F6';
export const BACKGROUND_SECONDARY_COLOR = '#252DBC';

export const BORDER_PRIMARY_COLOR = '#d5d9e3';
export const BORDER_SECONDARY_COLOR = '#BBE2CA';
export const BORDER_TERTIARY_COLOR = '#D3D3D3';

export const APP_PRIMARY_COLOR = '#04A6D6';
export const APP_SECONDARY_COLOR = '#3839AA';
export const APP_PRIMARY_COLOR_1 = '05a6d7';

export const FONT_PRIMARY_COLOR = '#1D1D24';
export const FONT_SECONDARY_COLOR = '#3E3E46';
export const FONT_TERTIARY_COLOR = '#b1b4be';
export const FONT_OTHER_COLOR = '#151414';

export const DEFAULT_WHITE_COLOR = '#FFFFFF';
export const DEFAULT_BLACK_COLOR = '#000000';

export const SEARCH_BAR_BACKGROUND_COLOR = '#E6E6E6';

export const WHITE_BACKGROUND_COLOR = '#fbfbfb';
export const SECONDARY_BACKGROUND_COLOR = '#FAFAFA';

export const PUBLIC_TOP_NAV_BAR_BORDER = '#CDCAD0';

export const LIGHT_GREY_COLOR = '#ececec';
export const LIGHT_WHITE_COLOR = '#F7F7F7';

export const SECONDARY_GRAY_COLOR_CODE = '#D8D8D8';
export const TERTIARY_GRAY_COLOR_COD = '#E8E8E8';

export const PLACEHOLDER_TEXT_COLOR = '#9D9D9D';

export const LIST_HOVER_COLOR = '#D9F0F7';
export const BUTTON_BORDER_COLOR = '#e0bdbb';
export const BUTTON_BACKGROUND_COLOR = '#ffeeed';
export const DISABLED_BACKGROUND_COLOR = '#e9ecef';

export const GREY_COLOR = '#F8F8F8';
export const GREY_COLOR_3 = '#646467';
export const GREY_COLOR_4 = '#F9F9F9';

export const GREY_BORDER_COLOR = '#D5D5D5';

export const BORDER_BOX_COLOR = '#c4c4c4';

export const RIGHT_BORDER_COLOR = '#e3e3e3';

export const MEDICINE_BORDER_BOX_COLOR = '#dbe4e6';
export const MESSAGE_BORDER_COLOR = '#B7B7B7';

// status colors
export const APP_BOOKED_STATUS_COLOR = '#FFEDED';
export const APP_BOOKED_STATUS_DOT_COLOR = '#FF6060';
export const APP_BOOKED_STATUS_DOT_COLOR_1 = '#FFA800';
export const APP_BOOKED_STATUS_DOT_COLOR_2 = '#5D5FEF';
export const APP_TRIAGE_STATUS_COLOR = '#CBF3FF';

export const APP_COMPLETED_STATUS_COLOR = '#E3FFEE';
export const APP_COMPLETED_STATUS_DOT_COLOR = '#09B75A';

export const APP_CONFIRM_STATUS_COLOR = '#C2F884';
export const APP_CONFIRM_STATUS_DOT_COLOR = '#689F38';

export const APP_RESCHEDULED_STATUS_COLOR = '#DDE0FC';
export const APP_RESCHEDULED_STATUS_DOT_COLOR = '#5464F3';

export const APP_RE_CONSULTING_STATUS_COLOR = '#BEF7FA';
export const APP_RE_CONSULTING_STATUS_DOT_COLOR = '#00CBD3';
export const APP_RE_CONSULTING_STATUS_DOT_COLOR_1 = '#14AE89';
export const APP_RE_CONSULTING_STATUS_DOT_COLOR_2 = '#0471D6';
export const APP_RE_CONSULTING_STATUS_DOT_COLOR_3 = '#CC3C3C';
export const APP_RE_CONSULTING_STATUS_DOT_COLOR_4 = '#FFA800';
export const APP_RE_CONSULTING_STATUS_DOT_COLOR_5 = '#AB4FF3';
export const APP_CANCELLED_STATUS__COLOR = '#FAB39D';
export const APP_CANCELLED_STATUS_COLOR_DOT_COLOR = '#E64A19';

export const APP_CLOSED_STATUS__COLOR = '#FDE0EF';
export const APP_CLOSED_STATUS_COLOR_DOT_COLOR = '#EF5DA8';

export const APP_IN_PROCESS_STATUS_COLOR = '#FFF6D2';
export const APP_IN_PROCESS_STATUS_DOT_COLOR = '#FFCE00';

export const APP_CHECK_IN_STATUS_COLOR = '#F7FFA8';
export const APP_CHECK_IN_STATUS_DOT_COLOR = '#CDDC39';

export const APP_CONSULTING_STATUS_COLOR = '#CFD8DC';
export const APP_CONSULTING_STATUS_DOT_COLOR = '#4F6B79';

export const APP_UNDER_GOING_STATUS_COLOR = '#B491F2';
export const APP_UNDER_GOING_STATUS_DOT_COLOR = '#512DA8';

export const APP_PAID_STATUS_COLOR = '#DEA057';
export const APP_PAID_STATUS_DOT_COLOR = '#6E4522';

export const APPOINTMENT_BORDER_COLOR = '#E0E0E0';
export const NORMAL_BMI_COLOR = 'green';
export const AB_NORMAL_BMI_COLOR = 'red';
export const MEDIUM_BMI_COLOR = '#ffbf00';

export const IN_PROGRESS = '#FFCE0080';
export const IN_PROGRESS_TEXT_COLOR = '#9E820C';
export const PAYMENT_DONE = 'rgba(4, 166, 214, 0.5)';
export const PAYMENT_DONE_TEXT_COLOR = '#0C6A9E';
export const IN_TRANSIT = '#FB7A32';
export const ORDER_DECLINE = 'rgba(255, 31, 0, 0.3)';
export const ORDER_DECLINE_TEXT_COLOR = '#CC4B4C';
export const PARTIAL_REPORTS = '#C7D36F';
export const SAMPLE_COLLECTED = '#D2C4F8';
export const PARTIALLY_COLLECTED = '#E5D9B6';

export const STATUS_COMPLETED_COLOR = '#09B75A';

// Change scale
export const GET_8_PIXEL_BOX = (unit: number): string => {
  // multiple of 8 pixels and 'px' is default unit used
  return `${8 * unit}px`;
};
