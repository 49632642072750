import {ADVISOR_SETTERS} from '../actions/Index';
import {AdvisorClientOnBoardType} from '../ReducerTypes/ReducerTypes';

const initAdvisor: {clientOnboard: AdvisorClientOnBoardType} = {
  clientOnboard: {
    unsaved: false,
    inviteClient:false
  }
};

export default function (state = initAdvisor, action: any = {}): any {
  const {type, payload} = action;
  if (type === ADVISOR_SETTERS.SET_CLIENT_ONBOARD_DATA) {
    return {
      ...state,
      clientOnboard: {...state.clientOnboard, ...payload}
    };
  } else {
    return state;
  }
}
